import React from "react";

const Sideprojects = () => {
  return (
    <div className="sideprojects" id="sideProjects">
      <div className="sideprojects-title">Side projects</div>
      <div className="sideprojects-grid">
        <a
          className="sideprojects-grid-item"
          href="https://socialsizes.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="arrow"
            width="40"
            height="40"
            fill="#686868"
            viewBox="0 0 32 32"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6893 8.25H8V6.75H17.25V16H15.75V9.31066L7.53033 17.5303L6.46967 16.4697L14.6893 8.25Z"
            ></path>
          </svg>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="36"
              viewBox="0 0 27 36"
              fill="none"
            >
              <rect
                width="26.957"
                height="35.686"
                x=".021"
                fill="url(#a)"
                rx="4"
              />
              <rect
                width="15.147"
                height="23.876"
                x="5.67"
                y="6.162"
                fill="#fff"
                rx="1"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1=".021"
                  x2=".021"
                  y1="0"
                  y2="35.686"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFCF00" />
                  <stop offset=".464" stopColor="#FF6C14" />
                  <stop offset="1" stopColor="#FF007B" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="label">Side project</div>
          <div className="title">Social Sizes.io</div>
          <div className="description">
            A simple website to find the correct image and video sizes for
            Social Media.
          </div>
        </a>
        <a
          className="sideprojects-grid-item"
          href="https://socialimagemaker.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            className="arrow"
            width="40"
            height="40"
            fill="#686868"
            viewBox="0 0 32 32"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6893 8.25H8V6.75H17.25V16H15.75V9.31066L7.53033 17.5303L6.46967 16.4697L14.6893 8.25Z"
            ></path>
          </svg>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
            >
              <rect
                width="29.598"
                height="29.598"
                x="29.598"
                y="29.598"
                fill="url(#a)"
                rx="4"
                transform="rotate(-180 29.598 29.598)"
              />
              <rect
                width="19.408"
                height="19.408"
                x="5.095"
                y="5.095"
                fill="#fff"
                rx="1"
              />
              <rect
                width="29.598"
                height="29.598"
                x="11.402"
                y="11.402"
                fill="url(#b)"
                rx="4"
              />
              <rect
                width="19.408"
                height="19.408"
                x="16.497"
                y="16.497"
                fill="#fff"
                rx="1"
              />
              <defs>
                <linearGradient
                  id="a"
                  x1="44.397"
                  x2="44.397"
                  y1="29.598"
                  y2="59.195"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFCF00" />
                  <stop offset=".396" stopColor="#FF6C14" />
                  <stop offset="1" stopColor="#FF007B" />
                </linearGradient>
                <linearGradient
                  id="b"
                  x1="26.201"
                  x2="26.201"
                  y1="11.402"
                  y2="41"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FFCF00" />
                  <stop offset=".396" stopColor="#FF6C14" />
                  <stop offset="1" stopColor="#FF007B" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="label">Side project</div>
          <div className="title">Social Image Maker.io</div>
          <div className="description">
            A simple and easy-to-use interface I made for creating images for
            Social Media.
          </div>
        </a>
        <a
          className="sideprojects-grid-item"
          target="_blank"
          rel="noopener noreferrer"
          href="https://goderestauranter.dk"
        >
          <svg
            className="arrow"
            width="40"
            height="40"
            fill="#686868"
            viewBox="0 0 32 32"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6893 8.25H8V6.75H17.25V16H15.75V9.31066L7.53033 17.5303L6.46967 16.4697L14.6893 8.25Z"
            ></path>
          </svg>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="32"
              viewBox="0 0 28 32"
              fill="none"
            >
              <path
                fill="#141414"
                d="M28 4.5v26c0 .438-.188.813-.5 1.063-.25.312-.625.437-1 .437-.438 0-.813-.125-1.063-.438-.312-.25-.437-.625-.437-1.062V22h-3.063c-.562 0-1.062-.063-1.562-.25-.5-.25-.938-.5-1.313-.875s-.625-.875-.875-1.313c-.187-.5-.25-1.062-.25-1.562L18 10.875c-.063-2.313.688-4.563 1.938-6.438C21.25 2.5 23.063 1.063 25.25.188c.188-.124.438-.124.75-.187.5 0 1 .25 1.375.625S28 1.5 28 2v2.5Zm-3-.813c-1.25.75-2.25 1.813-3 3.063-.688 1.25-1.063 2.688-1 4.125L20.937 18c0 .313.063.563.25.75.188.188.438.25.75.25h3L25 3.687ZM15 .5c.313.25.5.625.5 1v10c-.063 1.5-.625 2.875-1.625 3.938C12.812 16.438 11.437 17 10 17h-.5v13.5c0 .438-.188.813-.5 1.063-.25.312-.625.437-1 .437-.438 0-.813-.125-1.063-.438-.312-.25-.437-.625-.437-1.062V17H6c-1.5 0-2.875-.563-3.938-1.563A5.688 5.688 0 0 1 .5 11.5v-10c0-.375.125-.75.438-1C1.188.187 1.563 0 2 0c.375 0 .75.188 1 .5.313.25.5.625.5 1V9h3V1.625c0-.375.125-.75.438-1.063.25-.25.625-.437 1.062-.437.375 0 .75.188 1 .438.313.312.5.687.5 1.062V9h3V1.5c0-.375.125-.75.438-1 .25-.313.624-.5 1.062-.5.375 0 .75.188 1 .5Zm-3.5 12.938c.438-.376.75-.876.875-1.438H3.562c.126.563.438 1.063.876 1.438.437.374.937.562 1.562.562h4a2.24 2.24 0 0 0 1.5-.563Z"
              />
            </svg>
          </div>
          <div className="label">Side project</div>
          <div className="title">Gode Restauranter.dk</div>
          <div className="description">
            An overview of the best restaurants in Copenhagen. Handpicked by my
            wife and me.
          </div>
        </a>
      </div>
    </div>
  );
};

export default Sideprojects;
