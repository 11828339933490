import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

const Nav = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = (e) => {
    const scrollTop = window.pageYOffset;

    if (
      (scrollTop > 800 && window.innerWidth >= 760) ||
      (scrollTop > 64 && window.innerWidth < 760)
    ) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };
  return (
    <div className={hasScrolled ? "navbar headerScrolled" : "navbar"}>
      <nav>
        <ul>
          <li>
            <Link
              activeClass="active"
              to={"work"}
              spy={true}
              smooth={true}
              offset={-80}
              delay={120}
              duration={400}
            >
              Work
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to={"about"}
              spy={true}
              smooth={true}
              offset={-80}
              delay={120}
              duration={400}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to={"sideProjects"}
              spy={true}
              smooth={true}
              offset={-80}
              delay={120}
              duration={400}
            >
              Side Projects
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              to={"businesses"}
              spy={true}
              smooth={true}
              offset={-80}
              delay={120}
              duration={400}
            >
              Businesses
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
