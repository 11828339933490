import React from "react";
import peter_assentorp_png from "../images/peter_assentorp.png";
import peter_assentorp_webp from "../images/peter_assentorp.webp";
import { motion } from "framer-motion";

const Intro = () => {
  const line1 = "Hi, I'm Peter. A Freelance UI / UX Designer.";
  const line2 = "I create simple and beautiful user ";
  const line3 = "experiences, websites and interfaces.";

  const sentence = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
        staggerChildren: 0.015,
      },
    },
  };
  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="intro">
      <picture>
        <source
          srcSet={peter_assentorp_webp}
          type="image/webp"
          className="shadow"
        />
        <source
          srcSet={peter_assentorp_png}
          type="image/jpeg"
          className="shadow"
        />
        <img
          src={peter_assentorp_png}
          alt="Peter Assentorp"
          className="shadow"
        />
      </picture>
      <motion.div
        className="intro-text"
        variants={sentence}
        initial="hidden"
        animate="visible"
      >
        <div>
          {line1.split("").map((char, index) => {
            return (
              <motion.span key={char + "-" + index} variants={letter}>
                {char}
              </motion.span>
            );
          })}
        </div>
        <div>
          {line2.split("").map((char, index) => {
            return (
              <motion.span
                className="colored"
                key={char + "-" + index}
                variants={letter}
              >
                {char}
              </motion.span>
            );
          })}
        </div>
        <div>
          {line3.split("").map((char, index) => {
            return (
              <motion.span
                className="colored"
                key={char + "-" + index}
                variants={letter}
              >
                {char}
              </motion.span>
            );
          })}
        </div>
      </motion.div>
      <div className="intro-meta">
        <div className="intro-location">
          <motion.svg
            viewBox="0 0 18 17"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="intro-location-icon"
            initial={{ opacity: 0 }}
            animate={{
              opacity: [0, 1, 1, 1, 1],
              scale: [0, 0, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
            }}
            transition={{
              duration: 2,
              delay: 1.8,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
            }}
          >
            <path d="M17.008 2.133a1.264 1.264 0 0 0-.274-1.367 1.264 1.264 0 0 0-1.367-.274L1.617 6.117c-.547.235-.86.82-.742 1.406.117.586.625.977 1.25.977H9v6.875c0 .625.39 1.133.977 1.25a1.263 1.263 0 0 0 1.406-.742l5.625-13.75Z" />
          </motion.svg>
          <motion.div
            initial={{ x: -5, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ type: "linear", stiffness: 250, delay: 2 }}
            className="intro-location-text"
          >
            Copenhagen, Denmark
          </motion.div>
        </div>
        <div className="intro-mail">
          <motion.svg
            viewBox="0 0 14 11"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="intro-mail-icon"
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0, 1, 1, 1] }}
            transition={{
              duration: 2,
              delay: 1.8,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
            }}
          >
            <path d="M1.313.5h11.374C13.399.5 14 1.102 14 1.813c0 .437-.219.82-.547 1.066L7.52 7.336a.862.862 0 0 1-1.067 0L.52 2.879A1.309 1.309 0 0 1 0 1.812C0 1.103.574.5 1.313.5ZM0 3.563l5.934 4.484a1.764 1.764 0 0 0 2.105 0L14 3.562V9.25c0 .984-.793 1.75-1.75 1.75H1.75C.766 11 0 10.234 0 9.25V3.562Z" />
          </motion.svg>
          <motion.div
            className="intro-mail-text"
            initial={{ x: -5, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ type: "linear", stiffness: 250, delay: 2.2 }}
          >
            pa@tosider.dk
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
