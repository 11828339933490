import React, { useState } from "react";

const Footer = () => {
  const [currentYear] = useState(new Date().getFullYear());
  return (
    <footer>
      <div className="footer-left">
        © {currentYear} To Sider ApS &bull; CVR: 42753491
      </div>
      <div className="footer-right">
        <a
          href="https://dribbble.com/assentorp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path d="M11.719 3C6.902 3 3 6.938 3 11.719a8.717 8.717 0 0 0 8.719 8.719c4.781 0 8.719-3.903 8.719-8.72C20.438 6.939 16.5 3 11.717 3Zm5.73 4.043a7.226 7.226 0 0 1 1.688 4.64c-.246-.07-2.707-.562-5.168-.245-.211-.493-.387-.915-.668-1.477 2.777-1.125 4.008-2.707 4.148-2.918Zm-.808-.879c-.141.211-1.266 1.723-3.903 2.707a40.41 40.41 0 0 0-2.777-4.36 7.458 7.458 0 0 1 6.68 1.653ZM8.52 5.004c.21.281 1.546 2.11 2.777 4.324-3.48.914-6.54.914-6.89.879A7.545 7.545 0 0 1 8.52 5.004Zm-4.254 6.75v-.246c.316.035 3.937.07 7.629-1.055.246.422.421.844.632 1.266-2.707.773-5.168 2.953-6.363 4.992-1.195-1.3-1.898-3.059-1.898-4.957Zm2.847 5.871c.809-1.582 2.918-3.656 5.907-4.676 1.054 2.707 1.476 4.992 1.582 5.66a7.447 7.447 0 0 1-7.489-.984Zm8.754.281c-.07-.422-.492-2.601-1.441-5.308 2.32-.352 4.36.246 4.64.316a7.591 7.591 0 0 1-3.199 4.992Z" />
          </svg>
        </a>
        <a
          href="https://x.com/assentorp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path d="M16.6 5h2.454l-5.36 6.354L20 20h-4.937l-3.867-5.244L6.77 20H4.316l5.733-6.796L4 5h5.063l3.495 4.793L16.601 5Zm-.86 13.477h1.36L8.323 6.443H6.865l8.875 12.034Z" />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/in/peter-assentorp-0b0b5b1b0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path d="M18.625 4.375H5.09c-.598 0-1.09.527-1.09 1.16V19c0 .633.492 1.125 1.09 1.125h13.535c.598 0 1.125-.492 1.125-1.125V5.535c0-.633-.527-1.16-1.125-1.16Zm-9.879 13.5h-2.32v-7.488h2.32v7.488Zm-1.16-8.543c-.774 0-1.371-.598-1.371-1.336 0-.738.598-1.371 1.37-1.371.74 0 1.337.633 1.337 1.371s-.598 1.336-1.336 1.336Zm9.914 8.543h-2.355v-3.656c0-.844 0-1.969-1.196-1.969-1.23 0-1.406.95-1.406 1.934v3.691h-2.32v-7.488h2.214v1.02h.036c.316-.598 1.09-1.231 2.214-1.231 2.356 0 2.813 1.582 2.813 3.586v4.113Z" />
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
