import React from "react";
import { motion } from "framer-motion";
import peter_assentorp_jpg from "../images/peter_assentorp.jpg";
import peter_assentorp_webp from "../images/peter_assentorp.webp";
import driversnote from "../images/driversnoteclean.mp4";
const Bento = () => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  const variantstwo = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };
  return (
    <div className="bento" id="about">
      <div className="bento-title">About me</div>
      <div className="bento-grid">
        <div className="bento-grid-item">
          <div className="bgtwo">&nbsp;</div>
          <div className="bgone">&nbsp;</div>
          <div className="title">Minimalistic design.</div>
          <div className="description">
            I love designing interfaces that are simple and easy to use for
            anyone.
          </div>
          <div className="simpledesign">
            <div className="menu-outer">
              <div className="menu-label">menu</div>
              <ul className="menu">
                <li>Dashboard</li>
                <li>History</li>
                <li>Downloads</li>
              </ul>
              <div className="menu-spacer">&nbsp;</div>
              <ul className="menu second">
                <li>About</li>
                <li>Support</li>
                <li>Settings</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bento-grid-item">
          <div className="bgone">&nbsp;</div>
          <div className="title">Eye-catching animations.</div>
          <div className="description">
            I enjoy making CSS animations and promo videos that bring my designs
            to life.
          </div>
          <div className="video">
            <video
              src={driversnote}
              loop
              muted
              autoPlay
              playsInline
              className="shadow"
            />
            <br />
            <div className="madefor">Video made for Driversnote.com</div>
          </div>
        </div>
        <div className="bento-grid-item">
          <div className="title">Prototyping & Code.</div>
          <div className="description">
            I code my designs using HTML, CSS and Reactjs / Nextjs.
          </div>
          <div className="code">
            <div className="bgtwo">&nbsp;</div>
            <div className="bgone">&nbsp;</div>

            <motion.ul variants={variants} initial="closed" whileInView="open">
              <motion.li variants={variantstwo}>
                <span>&lt;div className="tools"&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;ul&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;li&gt;</span>
                Figma
                <span>&lt;/li&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;li&gt;</span>HTML<span>&lt;/li&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;li&gt;</span>CSS / Sass<span>&lt;/li&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;li&gt;</span>ReactJS / NextJS<span>&lt;/li&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;li&gt;</span>Claude AI<span>&lt;/li&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;li&gt;</span>Git<span>&lt;/li&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;ul&gt;</span>
              </motion.li>
              <motion.li variants={variantstwo}>
                <span>&lt;/div&gt;</span>
              </motion.li>
            </motion.ul>
          </div>
        </div>
        <div className="bento-grid-item">
          <div className="center-content">
            <div className="years">
              <span>15</span>&nbsp;&nbsp;
              <span>years</span>
            </div>
            <div className="experience">experience</div>
          </div>
        </div>
        <div className="bento-grid-item">
          {" "}
          <div className="work-contact">
            <picture>
              <source
                srcSet={peter_assentorp_webp}
                type="image/webp"
                className="shadow"
              />
              <source
                srcSet={peter_assentorp_jpg}
                type="image/jpeg"
                className="shadow"
              />
              <img
                src={peter_assentorp_jpg}
                alt="Peter Assentorp"
                className="shadow"
              />
            </picture>
            <div className="open-to-work">
              <span>Limited availability</span>
            </div>
            <div className="contact-me">pa@tosider.dk</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bento;
