import React from "react";
import amalovideo from "../images/amalo.mp4";
import amaloimage from "../images/amalo.jpg";
import amaloimagewebp from "../images/amalo.webp";
import skinbidimage from "../images/skinbid.jpg";
import skinbidimagewebp from "../images/skinbid.webp";
import formbackendimage from "../images/formbackend.jpg";
import formbackendimagewebp from "../images/formbackend.webp";
import altingetimage from "../images/altinget.jpg";
import altingetimagewebp from "../images/altinget.webp";
import driversnoteimage from "../images/driversnote.jpg";
import driversnoteimagewebp from "../images/driversnote.webp";
import Logos from "./Logos";

const Work = () => {
  return (
    <div className="work" id="work">
      <ul className="work-content">
        <li className="work-content-item">
          <div className="work-content-item-label">UI / UX</div>
          <div className="work-content-item-title">Amalo.dk</div>
          <div className="work-content-item-description">
            Over the span of a year, I assisted Amalo in revamping their visual
            identity. I designed a fresh new website, and created a new offer
            flow to increase conversions. I also designed an app to simplify
            communication between the on-site staff and the craftsmen.
          </div>
          <div className="work-content-item-border-top">&nbsp;</div>
          <div className="work-content-item-video">
            <picture>
              <source srcSet={amaloimagewebp} type="image/webp" />
              <source srcSet={amaloimage} type="image/jpeg" />
              <img src={amaloimage} alt="amalo.dk" />
            </picture>
            <video
              src={amalovideo}
              playsInline
              loop
              autoPlay
              muted
              className="shadow"
            />
          </div>
          <div className="work-content-item-border">&nbsp;</div>
        </li>
        <li className="work-content-item">
          <div className="work-content-item-label">UI</div>
          <div className="work-content-item-title">FormBackend.com</div>
          <div className="work-content-item-description">
            FormBackend makes it easy to get emails and messages from your HTML
            forms. The owner of FormBackend.com reached out to me to help with a
            redesign of their entire website. The goal was to make the website
            more modern and improve conversions and signups.
          </div>
          <div className="work-content-item-border-top">&nbsp;</div>
          <div className="work-content-item-video">
            <picture>
              <source srcSet={formbackendimagewebp} type="image/webp" />
              <source srcSet={formbackendimage} type="image/jpeg" />
              <img src={formbackendimage} alt="formbackend.com" />
            </picture>
          </div>
          <div className="work-content-item-border">&nbsp;</div>
        </li>
        <li className="work-content-item">
          <div className="work-content-item-label">
            UI / UX / Logo / Identity
          </div>
          <div className="work-content-item-title">SkinBid.com</div>
          <div className="work-content-item-description">
            SkinBid is a marketplace for buying and selling Counter-Strike
            skins. I was hired to design the entire website and the user
            interface for the app. I also did the logo and the visual identity.
            The project was super fun to do as I play the game myself.
          </div>
          <div className="work-content-item-border-top">&nbsp;</div>
          <div className="work-content-item-video">
            <picture>
              <source srcSet={skinbidimagewebp} type="image/webp" />
              <source srcSet={skinbidimage} type="image/jpeg" />
              <img src={skinbidimage} alt="skinbid.com" />
            </picture>
          </div>
          <div className="work-content-item-border">&nbsp;</div>
        </li>
        <li className="work-content-item">
          <div className="work-content-item-label">UI / UX</div>
          <div className="work-content-item-title">Altinget.dk</div>
          <div className="work-content-item-description">
            I assisted Altinget, a prominent Scandinavian online news media
            outlet focusing on politics, in designing multiple new pages,
            including a streamlined payment flow for their subscribers.
          </div>
          <div className="work-content-item-border-top">&nbsp;</div>
          <div className="work-content-item-video">
            <picture>
              <source srcSet={altingetimagewebp} type="image/webp" />
              <source srcSet={altingetimage} type="image/jpeg" />
              <img src={altingetimage} alt="altinget.dk" />
            </picture>
          </div>
          <div className="work-content-item-border">&nbsp;</div>
        </li>
        <li className="work-content-item">
          <div className="work-content-item-label">Design / Video</div>
          <div className="work-content-item-title">Driversnote.com</div>
          <div className="work-content-item-description">
            Driversnote is a mileage tracking app. I designed their AppStore
            images and also did a video for them you can see further down on the
            page.
          </div>
          <div className="work-content-item-border-top">&nbsp;</div>
          <div className="work-content-item-video">
            <picture>
              <source srcSet={driversnoteimagewebp} type="image/webp" />
              <source srcSet={driversnoteimage} type="image/jpeg" />
              <img src={driversnoteimage} alt="driversnote.com" />
            </picture>
          </div>
          <div className="work-content-item-border">&nbsp;</div>
        </li>
      </ul>
      <Logos />
    </div>
  );
};
export default Work;
