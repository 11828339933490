import React, { useState } from "react";
import Nav from "./Nav";

const Header = () => {
  const [workState] = useState(true);

  // const workMonth = "October";
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date();
  let currentMonth = month[d.getMonth()];

  return (
    <header>
      <div className="header-left">
        <div className="companyName">Freelance UI / UX Designer</div>
        <h1>Peter Assentorp</h1>
      </div>
      <Nav />
      <div className="header-right">
        {workState ? (
          <a href="mailto:pa@tosider.dk">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="11"
              fill="none"
            >
              <path
                fill="#141414"
                d="M1.75.875a.881.881 0 0 0-.875.875v1.094L6.207 6.78a1.357 1.357 0 0 0 1.559 0l5.359-3.937V1.75a.9.9 0 0 0-.875-.875H1.75ZM.875 3.938V8.75c0 .492.383.875.875.875h10.5a.881.881 0 0 0 .875-.875V3.937l-4.84 3.555a2.262 2.262 0 0 1-2.598 0L.875 3.938ZM0 1.75C0 .793.766 0 1.75 0h10.5C13.207 0 14 .793 14 1.75v7c0 .984-.793 1.75-1.75 1.75H1.75C.766 10.5 0 9.734 0 8.75v-7Z"
              />
            </svg>
            <span className="workstatus">
              <span className="title">Limited availability</span>
              <span className="badge">{currentMonth} 2023 - Onwards</span>
            </span>
          </a>
        ) : (
          <a href="mailto:pa@tosider.dk">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="11"
              fill="none"
            >
              <path
                fill="#141414"
                d="M1.75.875a.881.881 0 0 0-.875.875v1.094L6.207 6.78a1.357 1.357 0 0 0 1.559 0l5.359-3.937V1.75a.9.9 0 0 0-.875-.875H1.75ZM.875 3.938V8.75c0 .492.383.875.875.875h10.5a.881.881 0 0 0 .875-.875V3.937l-4.84 3.555a2.262 2.262 0 0 1-2.598 0L.875 3.938ZM0 1.75C0 .793.766 0 1.75 0h10.5C13.207 0 14 .793 14 1.75v7c0 .984-.793 1.75-1.75 1.75H1.75C.766 10.5 0 9.734 0 8.75v-7Z"
              />
            </svg>
            <span className="workstatus">
              <span className="title">Open to work</span>
              <span className="badge">{currentMonth} 2023 – Onwards</span>
            </span>
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;
