import React from "react";
import { motion } from "framer-motion";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Work from "./components/Work";
import Bento from "./components/Bento";
import Sideprojects from "./components/Sideprojects";
import Businesses from "./components/Businesses";
import Footer from "./components/Footer";
import "./styles/App.scss";
import bgImage from "./images/bg-white.jpg";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
  return (
    <div className="container">
      <motion.img
        animate={{ x: 0, rotate: 360 }}
        transition={{
          ease: "linear",
          duration: 320,
          repeat: Infinity,
          repeatType: "mirror",
        }}
        id="bg-image"
        alt="bg-image"
        src={bgImage}
      />
      <Header />
      <Intro />
      <Work />
      <Bento />
      <Sideprojects />
      <Businesses />
      <Footer />
      <Analytics />
    </div>
  );
};

export default App;
