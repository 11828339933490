import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo1 from "../images/altinget.svg";
import logo2 from "../images/rigsarkivet.svg";
import logo3 from "../images/karnovgroup.svg";
import logo4 from "../images/noaignite.svg";
import logo5 from "../images/issuu.svg";
import logo6 from "../images/driversnote.svg";
import logo7 from "../images/sundheddk.svg";
import logo8 from "../images/coop.svg";
import logo9 from "../images/sharewine.svg";
import logo10 from "../images/transloadit.svg";

const LogoTicker = () => {
  const [gap, setGap] = useState(24); // Initial gap value for mobile

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const newGap = windowWidth >= 768 ? 60 : 24; // Change gap based on window width
      setGap(newGap);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="logo-ticker">
      <motion.ul
        initial={{ x: 0 }}
        animate={{ x: -3200 }}
        transition={{
          duration: 48,
          ease: "linear",
          repeat: Infinity,
          repeatType: "loop",
        }}
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
          placeTtems: "center",
          margin: "0px",
          padding: "0px",
          listStyleType: "none",
          position: "relative",
          flexDirection: "row",
          willChange: "transform",
          gap: `${gap}px`, // Dynamically set the gap value
        }}
      >
        <li>
          <img src={logo1} alt="altinget" />
        </li>
        <li>
          <img src={logo2} alt="rigsarkivet" />
        </li>
        <li>
          <img src={logo3} alt="karnovgroup" />
        </li>
        <li>
          <img src={logo4} alt="noaignite" />
        </li>
        <li>
          <img src={logo5} alt="issuu" />
        </li>
        <li>
          <img src={logo6} alt="driversnote" />
        </li>
        <li>
          <img src={logo7} alt="sundheddk" />
        </li>
        <li>
          <img src={logo8} alt="coop" />
        </li>
        <li>
          <img src={logo9} alt="sharewine" />
        </li>
        <li>
          <img src={logo10} alt="transloadit" />
        </li>
        <li>
          <img src={logo1} alt="altinget" />
        </li>
        <li>
          <img src={logo2} alt="rigsarkivet" />
        </li>
        <li>
          <img src={logo3} alt="karnovgroup" />
        </li>
        <li>
          <img src={logo4} alt="noaignite" />
        </li>
        <li>
          <img src={logo5} alt="issuu" />
        </li>
        <li>
          <img src={logo6} alt="driversnote" />
        </li>
        <li>
          <img src={logo7} alt="sundheddk" />
        </li>
        <li>
          <img src={logo8} alt="coop" />
        </li>
        <li>
          <img src={logo9} alt="sharewine" />
        </li>
        <li>
          <img src={logo10} alt="transloadit" />
        </li>
        <li>
          <img src={logo1} alt="altinget" />
        </li>
        <li>
          <img src={logo2} alt="rigsarkivet" />
        </li>
        <li>
          <img src={logo3} alt="karnovgroup" />
        </li>
        <li>
          <img src={logo4} alt="noaignite" />
        </li>
        <li>
          <img src={logo5} alt="issuu" />
        </li>
        <li>
          <img src={logo6} alt="driversnote" />
        </li>
        <li>
          <img src={logo7} alt="sundheddk" />
        </li>
        <li>
          <img src={logo8} alt="coop" />
        </li>
        <li>
          <img src={logo9} alt="sharewine" />
        </li>
        <li>
          <img src={logo10} alt="transloadit" />
        </li>
      </motion.ul>
    </div>
  );
};

const Logos = () => {
  return (
    <div className="logos">
      <div className="logos-title">I have also worked with</div>
      <div className="logos-outer">
        <LogoTicker />
      </div>
    </div>
  );
};

export default Logos;
